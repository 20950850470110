<template>
  <div>
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />

    <v-container fill-height>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          xs="12"
          sm="8"
          md="4"
          lg="6"
          xl="4"
        >
          <v-fade-transition hide-on-leave>
            <v-skeleton-loader
              v-if="loading"
              height="301"
              class="mx-auto"
              type="image"
            />
          </v-fade-transition>

          <v-fade-transition hide-on-leave>
            <v-card
              v-if="!loading"
              outlined
            >
              <v-row justify="center">
                <v-card-title
                  v-if="isInvalidToken"
                  class="text-center"
                  v-text="$t('verify.invalidToken')"
                />

                <v-card-title
                  v-else
                  class="text-center"
                  v-text="$t('verify.confirmNewEmail')"
                />
              </v-row>

              <v-divider />
              
              <v-card-text>
                <div v-if="isInvalidToken">
                  <v-card-text v-text="`${$t('verify.invalidTokenText')} ${$t('verify.goToSettingsToken')}`" />

                  <v-divider class="my-2" />

                  <v-card-actions>
                    <v-row justify="center">
                      <v-btn
                        color="primary"
                        v-text="$t('verify.genNewToken')"
                        @click="goToGenNewToken"
                      />
                    </v-row>
                  </v-card-actions>
                </div>

                <div v-else>
                  <v-form
                    ref="form"
                    @submit.prevent="submit"
                  >
                    <v-text-field
                      v-model="password"
                      :append-icon="mdiLock"
                      :disabled="isInvalidToken"
                      validate-on-blur
                      name="password"
                      :label="$t('signin.pwd')"
                      type="password"
                      outlined
                      dense
                    />

                    <v-card-actions>
                      <v-row justify="center">
                        <v-btn
                          large
                          color="primary"
                          :disabled="isInvalidToken"
                          :loading="loadingBtn"
                          v-text="$t('verify.confirmEmailBtn')"
                          @click="submit"
                        />
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </div>
              </v-card-text>
            </v-card>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { SnackBar } from '@components/App'
import API from '@api'
import { mdiLock } from '@mdi/js'

export default {
  components: {
    SnackBar
  },

  async created () {
    
    const {token} = this.$route.query
    this.token = token

    if (token) {

      try {

        const params = {token}
        const data = await API().get('user/validate/email', {params})

        if (data) {

          this.fullName = data.fullName
          const [firstName] = this.fullName.split(' ')
          this.firstName = firstName
          this.newEmail = data.newEmail
          this.email = data.email
          this.id = data.user

        } else {

          this.snackMsg = 'expiredEmailToken'
          this.snackOn =  true
          this.isInvalidToken = true
        
        }

      } catch (err) {
      
        this.snackMsg = 'errEmailToken'
        this.snackOn = true
      
      }

    } else {

      this.$router.push({name: 'open.signin'})
    
    }

    this.loading = false
  },

  data () {
    return {
      mdiLock,
      
      snackMsg: '',
      snackOn: false,
      loading: true,
      loadingBtn: false,
      isInvalidToken: false,
      firstName: '',
      fullName: '',
      email: '',
      password: '',
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    ...mapMutations({
      SET_USER: 'user'  
    }),

    goToGenNewToken() {
      const name = !!this.user
        ? 'settings.home'
        : 'open.signin'
     
      this.$router.push({ name })
    },

    async submit() {
      this.loadingBtn = true

      if (this.$refs.form.validate()) {

        const id = this.id
        const email = this.email
        const password = this.password
        const params = { id, email, password }
        const userUpdateData = { email: this.newEmail, password }

        try {

          await API().put(`/user-update-email/${id}`, userUpdateData, { params })

          const userData = { ...this.user }
          userData.email = this.newEmail

          this.SET_USER(userData)

          await API().delete('/user/validate/delete', {
            params: {
              token: this.token
            }
          })

          this.$router.push({name: 'open.signin', params: {
              snackMsg: 'emailChanged'
            }
          })

        } catch (err) {

          this.snackMsg = err.response.data.errMsg
          this.snackOn = true

        } finally {

          this.loadingBtn = false

        }
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 200% !important;
  font-weight: 300; 
  margin: 0;
}
a {
  text-decoration: none;
}
</style>